import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';


import './App.css';

import ScrollToTop from "./components/scrollToTop";
import Header from "./components/Header";
import Footer from './components/Footer';
import CookieWarning from "./components/CookieWarning";
import Metrics from "./components/metrics"

import Home from './pages/Home';
import About from "./pages/About";
import Works from "./pages/Works";
import Contact from "./pages/Contact";
import Smi from "./pages/Smi";
import Error from "./pages/Error";
import Brief from "./pages/Brief";
import VacancyPage from "./pages/Vacancy";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";

import Placement from "./pages/Placement";
import Analytics from "./pages/Analytics";
import Web from "./pages/Web";
import Seo from "./pages/Seo";

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <ScrollToTop>
              <Header/>
              <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/about" component={About} />
                  <Route path="/works" component={Works} />
                  <Route path="/contacts" component={Contact} />
                  <Route path="/smi" component={Smi} />
                  <Route path="/brief" component={Brief} />
                  <Route path="/vacancy/:id" component={VacancyPage} />
                  <Route path="/blog/:code" component={BlogDetail} />
                  <Route path="/blog" component={Blog} />
                  <Route path="/services/advertising" component={Placement}/>
                  <Route path="/services/analytics" component={Analytics}/>
                  <Route path="/services/web" component={Web}/>
                  <Route path="/services/seo" component={Seo}/>
                  <Route path="*" render={({ staticContext }) => {
                      if (staticContext) {
                          staticContext.statusCode = 404
                      }
                      return <Error />
                  }}/>
              </Switch>
              <Footer/>
              <CookieWarning />
              <Metrics/>
          </ScrollToTop>
        </BrowserRouter>
    );
  }
}

export default App;