import React, {useEffect} from 'react';
import BannerAnimation from "../components/BannerAnimation";
import RightArrowBtn from "../components/RightArrowBtn";

const Error = () => {
    useEffect(() => {
        document.title = `Такой страницы не найдено`;
    });

    return (
        <section className="error__page">
            <div className="wrapper">
                <div className="error__box">
                    <div className="error__box-content">
                        <h1 className="error__box-title">404</h1>
                        <div className="error__box-text text">
                            Как мы ни старались, но данную страницу так и не смогли найти. Давайте попробуем еще раз, начиная с главной.
                        </div>
                        <div className="error__box-link">
                            <RightArrowBtn text="На главную" link="/"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="error__box-animation">
                <BannerAnimation error/>
            </div>
        </section>
    );
}
export default Error;