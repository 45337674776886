import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import {Helmet} from "react-helmet";

const blogItems = [
    {
        'name': 'Вопросы, которые раздражают в соцсетях',
        'img': '/_images/blog_voprosy.svg',
        'bigImage': '/_images/blog_detail_question.jpg',
        'text': 'Люди спрашивают в комментариях и в директе одно и тоже, и это уже становится похожим на глупость и невнимательность, вызывает раздражение у продавцов',
        'section': 'SMM',
        'code': 'questions'
    },
    // {
    //     'name': 'Оптимизация сайта: работа или сизифов труд?',
    //     'img': '/_images/blog_small.svg',
    //     'bigImage': '/_images/blog_big.svg',
    //     'section': 'Мысли вслух',
    //     'text': 'Годы идут, а SEO все то же. Или нет? Что изменилось сегодня? Почему SEO в настоящее время это уже не куча бесплатных ссылок на чужих сайтах и криво написанные тексты',
    //     'code': 'optimization'
    // },
    // {
    //     'name': 'От «вашей» рекламы так никто и не пришел...',
    //     'img': '/_images/blog_small.svg',
    //     'bigImage': '/_images/blog_big.svg',
    //     'section': 'Маркетинг',
    //     'text': 'Контекстная реклама хоть вещь и эффективная, но капризная. Неправильная настройка не только мешает получить новых клиентов, но и приводит к финансовым затратам.',
    //     'code': 'reklama'
    // },
]

const Blog = () => {
    useEffect(() => {
        // document.title = `Блог`;
    });

    return (
        <section className="blog__page">
            <Helmet>
                <title>Блог | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="Публикуем статьи в блоге про интернет продвижение, WEB-разработку, аналитику и всё, что с этим связано ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <div className="wrapper">
                <div className="blog__list">
                {
                    blogItems.map((post, i) => {
                        if (i === 0) {
                            return (
                                <NavLink to={`/blog/` + post.code} className="blog__post-link" key={`blog_item_` + i}>
                                    <div className="blog__post-big">
                                        <div className="blog__post-big__name h1">{post.name}</div>
                                        <div className="blog__post-big__image">
                                            <img src={post.bigImage} alt={post.name} />
                                        </div>
                                    </div>
                                </NavLink>
                            )
                        } else {
                            return (
                                <NavLink to={`/blog/` + post.code} className="blog__post-link"  key={`blog_item_` + i}>
                                    <div className="blog__post">
                                        <div className="blog__post-image">
                                            <img src={post.img} alt={post.name} />
                                        </div>
                                        <div className="blog__post-content">
                                            <div className="blog__post-section">{post.section}</div>
                                            <div className="blog__post-name h3">{post.name}</div>
                                            <div className="blog__post-text text">{post.text}</div>
                                        </div>
                                    </div>
                                </NavLink>
                            )
                        }
                    })
                }
                </div>
            </div>
        </section>
    );
}

export default Blog;