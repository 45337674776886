import React, {useEffect} from 'react';
import SmiItem from "../components/smiItem";
import {Helmet} from "react-helmet";

const smiItems = [
    {
        'link': 'https://cmsmagazine.ru/journal/items-how-to-turn-a-boring-bank-group/',
        'img': '/_images/cms_magazine_logo.svg',
        'top_description': 'Статья',
        'title': 'Как превратить скучную группу банка в содержательную ленту Facebook',
        'description': 'Советы для тех, кто решил создать представительство банка в соцсетях или тех, кто уже занимается этим и не видит результатов.'
    },
    {
        'link': 'https://cmsmagazine.ru/journal/items-how-to-raise-a-sales-manager/',
        'img': '/_images/cms_magazine_logo.svg',
        'top_description': 'Статья',
        'title': 'Как воспитать менеджера по продажам в молодой веб-студии',
        'description': 'Советы для молодых веб-студий: с чего начинается работа отдела продаж'
    },
    {
        'link': '/_images/bizmag.jpg',
        'img': '/_images/business_journal_logo.svg',
        'top_description': 'Выступление',
        'title': 'Кризис - не помеха развитию бизнеса в интернете',
        'description': 'Обучающий семинар вместе с мобильным оператором Tele2 в Костроме'
    },
]

const Smi = () => {
    useEffect(() => {
        // document.title = `Агентство в СМИ`;
    });

    return (
        <section className="smi__page">
            <Helmet>
                <title>Сми о нас | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="В данном разделе публикуются последние упоминания о нашей компании. Следите за обновлением информации на сайте ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <div className="wrapper">
                <h1 className="smi__title h1">Агентство в СМИ</h1>
                <div className="smi__description text">
                    В своей песочнице порой становится тесно, поэтому иногда мы выступаем публично и делимся своим опытом и идеями в интернет-маркетинге.
                </div>
                <div className="smi__items">
                    {
                        smiItems.map((post, i) => {
                            return (<SmiItem item={post} key={`smi_post_` + i}/>);
                        })
                    }
                </div>
            </div>
        </section>
    );
}
export default Smi;