import React from 'react';
import { NavLink } from 'react-router-dom';
import KeypointItem from "./KeypointItem";
import HeaderArrow from "../svg/header_service_arrow.svg";
import MenuIcon from "../svg/menu.svg";

const keypointItems = [
    {
        'link': '/services/advertising',
        'img': 'advertising_header',
        'title': 'Реклама',
        'description': 'Настраиваем рекламу для новых заявок и покупателей из поисковых систем. Объединяем все основные инструменты для получения продающего трафика.'
    },
    {
        'link': '/services/seo',
        'img': 'search_header',
        'title': 'Поисковая оптимизация',
        'description': 'Поможем найти и исправить ошибки, мешающие сайту попасть на первую страницу выдачи поисковых систем, сделаем его удобнее для пользователя.'
    },
    {
        'link': '/services/web',
        'img': 'web_header',
        'title': 'Веб-разработка',
        'description': 'Специализируемся на разработке и развитии сайтов. Видим каждую страницу как интерфейс, требующий не только оформления, но и проработки контента.'
    },
    {
        'link': '/services/analytics',
        'img': 'analytics_header',
        'title': 'Аналитика',
        'description': 'Вид  исследования, помогающий определить проблемы и поставить задачи будущего проекта. Основа для принятия верных решений.'
    },
]

class Header extends React.Component {
    state = {
        lastScrollTop: 0,
        timeoutDropdown: false
    }

    onScroll = (e) => {
        let headerBox = document.getElementsByClassName('header__box')[0];
        if (e.target.documentElement.scrollTop > 0 && !headerBox.classList.contains('fixed')) {
            headerBox.classList.add('fixed');
            headerBox.style.top = 0;
        }
        if (e.target.documentElement.scrollTop < 1 && headerBox.classList.contains('fixed')) {
            headerBox.classList.remove('fixed');
            headerBox.style.top = 0;
        }
        if (headerBox.classList.contains('fixed')) {
            if (e.target.documentElement.scrollTop > this.state.lastScrollTop) {
                if (parseInt(headerBox.style.top) * -1 < 165) {
                    let nowTop = parseInt(headerBox.style.top);
                    nowTop -= (e.target.documentElement.scrollTop - this.state.lastScrollTop);
                    if (nowTop < -165) nowTop = -165;
                    headerBox.style.top = nowTop + 'px';
                }
            } else {
                if (parseInt(headerBox.style.top) * -1 > 0) {
                    let nowTop = parseInt(headerBox.style.top);
                    nowTop += (this.state.lastScrollTop - e.target.documentElement.scrollTop);
                    if (nowTop > 0) nowTop = 0;
                    headerBox.style.top = nowTop + 'px';
                }
            }
        }
        this.setState({
            lastScrollTop: e.target.documentElement.scrollTop
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        document.getElementsByClassName('header__menu-open')[0].addEventListener('click', () => {
            document.getElementsByTagName('body')[0].classList.add('no-scroll');
            document.getElementsByClassName('header__mobile-menu')[0].classList.add('showed');
        });
        document.getElementsByClassName('header__mobile-menu__close')[0].addEventListener('click', () => {
            document.getElementsByTagName('body')[0].classList.remove('no-scroll');
            document.getElementsByClassName('header__mobile-menu')[0].classList.remove('showed');
        });
        document.getElementsByClassName('header__menu-mobile__services-back')[0].addEventListener('click', () => {
            document.getElementsByClassName('header__mobile-menu__links-services')[0].classList.remove('showed');
        });

        const mobileLinks = document.getElementsByClassName("header__mobile-menu__link");
        for (let i = 0; i < mobileLinks.length; i++) {
            mobileLinks[i].addEventListener('click', () => {
                document.getElementsByTagName('body')[0].classList.remove('no-scroll');
                document.getElementsByClassName('header__mobile-menu')[0].classList.remove('showed');
            })
        }

        document.getElementsByClassName('header__mobile-menu__bottom-brief')[0].addEventListener('click', () => {
            document.getElementsByTagName('body')[0].classList.remove('no-scroll');
            document.getElementsByClassName('header__mobile-menu')[0].classList.remove('showed');
        });
    }

    clearTimeoutState = () => {
        this.setState({timeoutDropdown: false})
    }

    mouseEnterProjects() {
        if (this.state.timeoutDropdown) {
            clearTimeout(this.state.timeoutDropdown);
            this.setState({timeoutDropdown: false})
        } else {
            const that = this;
            this.setState({
                timeoutDropdown: setTimeout(function () {
                    document.getElementsByClassName('header__dropdown-menu')[0].classList.add('active');
                    document.getElementsByClassName('header__menu-services__arrow')[0].classList.add('active');
                    that.clearTimeoutState();
                }, 200)
            })
        }
    }

    mouseLeaveProjects() {
        if (this.state.timeoutDropdown) {
            clearTimeout(this.state.timeoutDropdown);
            this.setState({timeoutDropdown: false})
        } else if (!this.state.timeoutDropdown && document.getElementsByClassName('header__dropdown-menu')[0].classList.contains('active')) {
            const that = this;
            this.setState({
                timeoutDropdown: setTimeout(function() {
                    document.getElementsByClassName('header__dropdown-menu')[0].classList.remove('active');
                    document.getElementsByClassName('header__menu-services__arrow')[0].classList.remove('active');
                    that.clearTimeoutState();
                }, 200)
            })
        }
    }

    handleClickProjects = (e) => {
        e.preventDefault()
    }

    closeMenu() {
        document.getElementsByClassName('header__dropdown-menu')[0].classList.remove('active');
        this.setState({timeoutDropdown: false})
    }

    openServicesMenuMobile = (e) => {
        console.log(e);
        e.preventDefault();
        document.getElementsByClassName('header__mobile-menu__links-services')[0].classList.add('showed');
    }

    render() {
        return (
            <div className="header__section">
                <div className="wrapper">
                    <div className="header__place"/>
                </div>
                <div className="header__box">
                    <div className="wrapper">
                        <div className="header">
                            <div className="header__menu-open mobile-only">
                                <img src={MenuIcon} alt="" />
                            </div>
                            <NavLink exact to="/" className="header__logo" title="Главная">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="50" height="50" rx="25" fill="currentColor"/>
                                    <path d="M26.5714 15H14V22.5251H17.3642C17.2314 23.1449 17.1871 23.7646 17.1871 24.4286C17.1429 25.4024 16.3018 26.1992 15.328 26.1992H14V33.7243H15.3722C20.5513 33.6801 24.7123 29.4748 24.7123 24.2958C24.7565 23.3219 25.5976 22.5251 26.5714 22.5251C27.5895 22.5251 28.4748 23.3662 28.4748 24.4286V33.8571H36V24.4286C36 19.2052 31.7948 15 26.5714 15Z" fill="white"/>
                                </svg>
                            </NavLink>
                            <nav className="header__menu">
                                <NavLink exact to="/"><span>Об агентстве</span></NavLink>
                                <NavLink to="/services" className="header__menu-services" onMouseLeave={this.mouseLeaveProjects.bind(this)} onMouseEnter={this.mouseEnterProjects.bind(this)} onClick={this.handleClickProjects.bind(this)}>
                                    <span>Услуги</span>
                                    <img src={HeaderArrow} className="header__menu-services__arrow" alt=""/>
                                </NavLink>
                                <NavLink to="/works" className="header__menu-project" data-count="4"><span>Проекты</span></NavLink>
                                <a href="https://auto.plastilin-lab.ru/" target="_blank" rel="noopener noreferrer" className="header__menu-auto">Автодилерам</a>
                                <NavLink to="/about#vacancy" className="header__menu-work" data-count="2"><span>Работа</span></NavLink>
                                <NavLink to="/contacts"><span>Контакты</span></NavLink>
                            </nav>
                        </div>
                    </div>
                    <div className="header__dropdown-menu" onMouseEnter={this.mouseEnterProjects.bind(this)} onMouseLeave={this.mouseLeaveProjects.bind(this)}>
                        <div className="wrapper">
                            <div className="header__dropdown-box">
                                <div className="keys__items">
                                    {
                                        keypointItems.map((keypoint, i) => {
                                            return (<KeypointItem keypoint={keypoint} onClick={this.closeMenu.bind(this)} key={`keypoint_header_` + i}/>);
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header__mobile-menu mobile-only">
                    <button className="header__mobile-menu__close">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8323 9.99994L19.6199 2.21211C20.1267 1.70554 20.1267 0.886491 19.6199 0.379925C19.1133 -0.126642 18.2943 -0.126642 17.7877 0.379925L9.99988 8.16776L2.21228 0.379925C1.70548 -0.126642 0.886669 -0.126642 0.380103 0.379925C-0.126701 0.886491 -0.126701 1.70554 0.380103 2.21211L8.1677 9.99994L0.380103 17.7878C-0.126701 18.2943 -0.126701 19.1134 0.380103 19.62C0.632555 19.8726 0.964493 19.9996 1.29619 19.9996C1.62789 19.9996 1.95959 19.8726 2.21228 19.62L9.99988 11.8321L17.7877 19.62C18.0404 19.8726 18.3721 19.9996 18.7038 19.9996C19.0355 19.9996 19.3672 19.8726 19.6199 19.62C20.1267 19.1134 20.1267 18.2943 19.6199 17.7878L11.8323 9.99994Z" fill="black"/>
                        </svg>
                    </button>
                    <div className="header__mobile-menu__links">
                        <NavLink exact to="/" className="header__mobile-menu__link"><span>Об агентстве</span></NavLink>
                        <NavLink to="/services" className="header__menu-mobile__services" onClick={this.openServicesMenuMobile.bind(this)}>
                            <span>Услуги</span>
                            <img src={HeaderArrow} alt=""/>
                        </NavLink>
                        <NavLink to="/works" className="header__mobile-menu__link"><span className="header__mobile-menu__link-project" data-count="4">Проекты</span></NavLink>
                        <a href="https://auto.plastilin-lab.ru/" target="_blank" rel="noopener noreferrer" className="header__mobile-menu__link"><span className="header__mobile-menu__link-dealer">Автодилерам</span></a>
                        <NavLink to="/about" className="header__mobile-menu__link"><span>Наш офис</span></NavLink>
                        <NavLink to="/about#vacancy" className="header__mobile-menu__link"><span>Вакансии</span></NavLink>
                        <NavLink to="/smi" className="header__mobile-menu__link"><span>СМИ о нас</span></NavLink>
                        <NavLink to="/blog" className="header__mobile-menu__link"><span>Блог</span></NavLink>
                        <NavLink to="/contacts" className="header__mobile-menu__link"><span>Контакты</span></NavLink>
                        <div className="header__mobile-menu__links-services">
                            <div className="header__menu-mobile__services-back">
                                <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.52858 0.333226L0.858548 3.0033C0.796744 3.06506 0.762695 3.1475 0.762695 3.2354C0.762695 3.3233 0.796744 3.40574 0.858548 3.46749L1.05513 3.66413C1.18323 3.79208 1.39142 3.79208 1.51932 3.66413L3.76145 1.422L6.00607 3.66661C6.06787 3.72837 6.15026 3.76247 6.23811 3.76247C6.32607 3.76247 6.40845 3.72837 6.47031 3.66661L6.66684 3.46998C6.72865 3.40818 6.7627 3.32579 6.7627 3.23789C6.7627 3.14998 6.72865 3.06755 6.66684 3.00579L3.99438 0.333226C3.93238 0.271324 3.8496 0.237324 3.7616 0.23752C3.67326 0.237324 3.59053 0.271324 3.52858 0.333226Z" fill="currentColor"/>
                                </svg>
                                <span>Услуги</span>
                            </div>
                            <NavLink to="/services/analytics" className="header__mobile-menu__link">Аналитика</NavLink>
                            <NavLink to="/services/web" className="header__mobile-menu__link">Разработка</NavLink>
                            <NavLink to="/services/seo" className="header__mobile-menu__link">SEO-оптимизация</NavLink>
                            <NavLink to="/services/advertising" className="header__mobile-menu__link">Реклама</NavLink>
                        </div>
                    </div>
                    <div className="header__mobile-menu__bottom">
                        <NavLink to="/brief" className="header__mobile-menu__bottom-brief">Заполнить бриф</NavLink>
                        <a href="mailto:info@plastilin-lab.ru" className="header__mobile-menu__bottom-link">info@plastilin-lab.ru</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;