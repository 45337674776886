import React, {useEffect} from 'react';
import RightArrowBtn from "../components/RightArrowBtn";
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import {Helmet} from "react-helmet";

const Contact = () => {
    useEffect(() => {
        // document.title = `Контакты`;
    });
    return (
        <section className="contact__page">
            <Helmet>
                <title>Контакты | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="Контакты компании: адрес, схема проезда, телефон, email ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <div className="wrapper">
                <div className="contact__top">
                    <div className="contact__title h1">Мы открыты для звонков и сообщений</div>
                    <div className="contact__address text">Будем рады увидеть вас в нашем уютном офисе по адресу: г. Кострома, ул. Ленина, дом 45, 2 этаж</div>
                </div>
                <div className="contact__items">
                    <div className="contact__item">
                        <div className="contact__item-title h3">Работа у нас</div>
                        <div className="contact__item-text text">Не стесняйтесь писать, даже если ваш опыт не соответствует заявленным требованиям. Мы всегда рассмотрим ваше предложение и поможем влиться в команду</div>
                        <RightArrowBtn text="Смотреть" link="/about#vacancy"/>
                    </div>
                    <div className="contact__item">
                        <div className="contact__item-title h3">Контактные телефоны</div>
                        <div className="contact__item-text text ">
                            <a href="tel:+74942500325">+ 7 494 250-03-25</a>
                        </div>
                    </div>
                </div>
                <div className="contact__map">
                    <YMaps>
                        <Map defaultState={{ center: window.screen.width < 585 ? [57.774367, 40.931497] : [57.780036, 40.938098], zoom: 14 }} width="100%" height="100%">
                            <Placemark geometry={[57.774367, 40.931497]} options={{
                                iconLayout: 'default#image',
                                iconImageHref: '/_images/map_icon.svg',
                                iconImageSize: [80, 80],
                                iconImageOffset: [-40, -40]
                            }} properties={{
                                balloonContent: '<div class="contact-map__balloon-title">Интернет-лаборатория Пластилин</div><div class="contact-map__balloon-content">г. Кострома, ул. Ленина, 45<br/><a href="tel:+74942500325">+ 7 494 250-03-25</a><br/><a href="mailto:info@plastilin-lab.ru">info@plastilin-lab.ru</a></div>'
                            }} modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                            />
                            <ZoomControl options={{ float: 'right' }} />
                        </Map>
                    </YMaps>
                </div>
            </div>
        </section>
    );
}
export default Contact;