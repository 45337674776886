import React from 'react';

const SmiItem = (params) => {
    const item = params.item;
    return (
        <a href={item.link} target="_blank" rel="noopener noreferrer" className="smi__item">
            <div className="smi__item-img">
                <img src={item.img} alt={item.title} />
            </div>
            <div className="smi__item-type">{item.top_description}</div>
            <div className="smi__item-name h3">{item.title}</div>
            <div className="smi__item-description text">{item.description}</div>
        </a>
    );
}

export default SmiItem;