import React from 'react';
import PageTopBanner from "../components/PageTopBanner";
import PageBottomBanner from "../components/PageBottomBanner";
import RightArrowBtn from "../components/RightArrowBtn";
import Product from "../components/Product";
import KeypointItem from "../components/KeypointItem";
import {Helmet} from "react-helmet";

const productsMain = [
    {
        'img': '/_images/zavarin_cover.jpg',
        'video': '/_video/zavarin_review.mp4',
        'tags': 'Брендинг / Дизайн / Разработка / Автоматизация',
        'name': 'Ювелирный дом ЗАВАРИН',
        'link': 'http://zavarin.art/',
        'link_text': 'zavarin.art',
        'description': 'Разработка логотипа и фирменного стиля для ювелирного производства из Костромы. Проектирование и дизайн интернет-магазина, автоматизация каталога с 1С Торговля.'
    },
    {
        'img': '/_images/polimer_cover.jpg',
        'video': '/_video/polimer_review.mp4',
        'tags': 'Дизайн / Разработка / Реклама / Поисковая оптимизация',
        'name': 'Полимерстройсервис',
        'link': 'https://psskos.ru/',
        'link_text': 'psskos.ru',
        'description': 'Создание сайта регионального производителя стальных и межкомнатных дверей Полиместройсервис. Разработка конфигуратора дверей для автоматизации оформления заказов в салонах продаж.'
    }
]

const keypointItems = [
    {
        'link': '/services/advertising',
        'img': 'advertising',
        'title': 'Реклама',
        'description': 'Настраиваем рекламу для новых заявок и покупателей из поисковых систем. Объединяем все основные инструменты для получения продающего трафика.'
    },
    {
        'link': '/services/seo',
        'img': 'search',
        'title': 'Поисковая оптимизация',
        'description': 'Поможем найти и исправить ошибки, мешающие сайту попасть на первую страницу выдачи поисковых систем, сделаем его удобнее для пользователя.'
    },
    {
        'link': '/services/web',
        'img': 'web',
        'title': 'Веб-разработка',
        'description': 'Специализируемся на разработке и развитии сайтов. Видим каждую страницу как интерфейс, требующий не только оформления, но и проработки контента.'
    },
    {
        'link': '/services/analytics',
        'img': 'analytics',
        'title': 'Аналитика',
        'description': 'Вид исследования, помогающий определить проблемы и поставить задачи будущего проекта. Основа для принятия верных решений.'
    },
]

export const animate = (elem, style, unit, from, to, time, prop) => {
    if (!elem) {
        return;
    }
    let start = new Date().getTime(), timer = setInterval(function () {
        let step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
            elem[style] = (from + step * (to - from))+unit;
        } else {
            elem.style[style] = (from + step * (to - from))+unit;
        }
        if (step === 1) {
            clearInterval(timer);
        }
        }, 25);

    if (prop) {
        elem[style] = from+unit;
    } else {
        elem.style[style] = from+unit;
    }
}

class Home extends React.Component {
    animateScrollToServices = (e) => {
        e.preventDefault();
        let target = document.getElementsByClassName("home__keys")[0];
        animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 50, 800, true);
    }

    render() {
        return (
            <section className="home__page">
                <Helmet>
                    <title>Интернет-лаборатория Пластилин | Интернет продвижение, SEO, Web-разработка и аналитика</title>
                    <meta name="description" content="Закажите эффективное интернет продвижение сайта с гарантией результата ✔ SEO-продвижение ✔ Интернет-реклама ✔ Web-разработка и аналитика ★ Интернет-лаборатория Пластилин" />
                </Helmet>
                <PageTopBanner home/>
                <div className="home__about">
                    <div className="wrapper">
                        <div className="home__about-title h3">Об агентстве</div>
                        <div className="home__about-text text">
                            <p>Пластилин - интернет-лаборатория, где всегда есть место эксперименту, будь то дизайн, разработка или рекламная стратегия. Как сделать продукт лучше? Как правильно его упаковать? Как донести преимущества до покупателя? Мы постоянно задаем себе эти вопросы, и в каждом проекте стараемся ответить на них. Ключевой ресурс для нас - не время, а энергия и опыт. Главные принципы - ответственность и доверительное отношение в команде, честность и открытость перед клиентом.</p>
                            {
                                window.screen.width > 767 && <p>Наше знакомство начинается с изучения специфики бизнеса, процессов, текущей ситуации в маркетинге. Мы любим общаться с нашими клиентами и всегда уважаем их мнение. Всегда готовы обсудить новую идею на сайте или анимацию рекламного баннера. Никогда не беремся за проект, если не понимаем его целей или сомневаемся в его успехе. </p>
                            }
                        </div>
                        <div className="home__about-link">
                            <RightArrowBtn text="Наши услуги" link="#" onClick={this.animateScrollToServices.bind(this)}/>
                        </div>
                    </div>
                </div>
                <div className="home__works">
                    <div className="wrapper">
                        <div className="home__works-title">Последние работы</div>
                        <div className="home__works-items">
                            {
                                productsMain.map((product, i) => {
                                    return (<Product product={product} key={`product_main_` + i} id={`product_main_` + i}/>);
                                })
                            }
                        </div>
                        <div className="home__works-link">
                            <RightArrowBtn text="Наши работы" link="/works"/>
                        </div>
                    </div>
                </div>
                <div className="home__keys">
                    <div className="wrapper">
                        <div className="home__keys-title">Направления работы</div>
                        <div className="keys__items">
                            {
                                keypointItems.map((keypoint, i) => {
                                    return (<KeypointItem keypoint={keypoint} key={`keypoint_main_` + i}/>);
                                })
                            }
                        </div>
                    </div>
                </div>
                <PageBottomBanner home/>
            </section>
        );
    }
}

export default Home;