import React from 'react';
import {NavLink} from "react-router-dom";
import RightArrow from "../svg/arrow_right_button.svg";

const RightArrowBtn = (params) => {
    return (
        <NavLink to={params.link} className="button-arrow__link" onClick={params.onClick}>
            <span>{params.text}</span>
            <img src={RightArrow} alt=""/>
        </NavLink>
    );
}

export default RightArrowBtn;