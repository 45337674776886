import React, {useEffect} from 'react';
import PageTopBanner from "../components/PageTopBanner";
import KeypointItem from "../components/KeypointItem";
import PageBottomBanner from "../components/PageBottomBanner";
import {Helmet} from "react-helmet";

const keypointItems = [
    {
        'img': 'first_audit',
        'title': 'Первичный аудит',
        'description': 'Поможет на раннем этапе обнаружить ошибки, которые мешают сайту попасть на первую страницу выдачи поисковых систем.'
    },
    {
        'img': 'external_optimization',
        'title': 'Внешняя оптимизация',
        'description': 'Внешняя оптимизация сайта помогает привлечь посетителей со страниц внешних ресурсов, а также повысить репутацию.'
    },
    {
        'img': 'inner_optimization',
        'title': 'Внутренняя оптимизация',
        'description': 'Внутренняя оптимизация помогает сделать сайт удобнее для пользователя, продвинуться в области показов поисковых систем.'
    },
    {
        'img': 'analytics',
        'title': 'Работа с аналитикой',
        'description': 'Помогает быстро реагировать на проблемы, понимать достигнутые результаты, а также находить новые точки для роста.'
    },
]

const Seo = () => {
    useEffect(() => {
        // document.title = `Поисковое продвижение`;
    });

    return (
        <section className="analytics__page">
            <Helmet>
                <title>SEO продвижение сайта | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="Закажите эффективное SEO продвижение сайта в поисковых системах с гарантией результата ✔ Первичный аудит ✔ Внешняя и внутренняя оптимизация ✔ Web-аналитика ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <PageTopBanner seo/>
            <div className="service-detail">
                <div className="wrapper">
                    <div className="service-detail__text">
                        <div className="service-detail__title h3">Услуга поисковой оптимизации</div>
                        <p>
                            Выбор агентства по продвижению всегда сложно для клиента. Во-первых, результат отсрочен, а оценить реальный эффект от проделанной работы можно не раньше 3-4 месяцев. Во-вторых, внедрение SEO требует высокой квалификации исполнителей. В-третьих, многие клиенты не осознают, что в работе с SEO агентство несет большую ответственность за проект.
                        </p>
                        <p>
                            Любая работа по оптимизации в нашей лаборатории начинается с аудита сайта. Мы проверяем техническое состояние проекта (скорость загрузки страниц, ошибки HTML разметки, метатеги и прочее), анализируем конкурентов, семантику, проектируем структуру сайта, а также формируем запрос по контенту. В каждом проекте мы согласуем с клиентом вопросы:
                        </p>
                        <ul>
                            <li>- какими силами будут реализовываться технические доработки и в какие сроки;</li>
                            <li>- как будет организована работа над контентом, в каком объеме; </li>
                            <li>- как часто будут формироваться отчеты и в каком виде;</li>
                            <li>- KPI и условия по договору.</li>
                        </ul>
                        <p>
                            В своей работе мы никогда не применяем серые или запрещенные методы продвижения, а также не обещаем:
                        </p>
                        <ul>
                            <li>- топ-10 за 2 месяца;</li>
                            <li>- рост трафика на 2000%;</li>
                            <li>- рост бизнеса на 200%;</li>
                            <li>- прочие сомнительные результаты.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="service__keys">
                <div className="wrapper">
                    <div className="service__keys-title">Разделяем в работе</div>
                    <div className="keys__items">
                        {
                            keypointItems.map((keypoint, i) => {
                                return (<KeypointItem keypoint={keypoint} key={`keypoint_main_` + i}/>);
                            })
                        }
                    </div>
                </div>
            </div>
            <PageBottomBanner services seo/>
        </section>
    );
}

export default Seo;