import React from 'react';
import InputMask from 'react-input-mask';
import RightArrowBtn from "../components/RightArrowBtn";

class VacancyPage extends React.Component {
    componentDidMount() {
        document.title = `Откликнуться на вакансию`;

        const elements = document.getElementsByClassName("brief__box-input");
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('focus', () => {
                elements[i].classList.remove('error-input');
            })
        }

        document.getElementsByClassName('brief__result-close')[0].addEventListener('click', () => {
            document.getElementsByClassName('brief__result-shadow')[0].classList.remove('showed');
        });
    }

    sendVacancyData = async () => {
        let formResult = document.getElementsByClassName('brief__result')[0];
        formResult.classList.remove('show');

        let sendObject = {
            'vacancy' : this.props.match.params.id
        };
        let sendAccess = true;

        if (document.querySelector('input[name="fio"]').value.length > 0) {
            sendObject.name = document.querySelector('input[name="fio"]').value;
        } else {
            document.querySelector('input[name="fio"]').classList.add('error-input');
            sendAccess = false;
        }

        if (document.querySelector('input[name="city"]').value.length > 0) {
            sendObject.city = document.querySelector('input[name="city"]').value;
        }

        if (document.querySelector('input[name="email"]').value.length > 0) {
            if (validateEmail(document.querySelector('input[name="email"]').value)) {
                sendObject.email = document.querySelector('input[name="email"]').value;
            } else {
                document.querySelector('input[name="email"]').classList.add('error-input');
                sendAccess = false;
                // formResult.textContent = 'Корректно заполните ваш e-mail';
                // formResult.classList.add('show');
                // return false;
            }
        } else {
            document.querySelector('input[name="email"]').classList.add('error-input');
            sendAccess = false;
            // formResult.textContent = 'Заполните ваш e-mail';
            // formResult.classList.add('show');
            // return false;
        }

        if (document.querySelector('input[name="phone"]').value.length > 0) {
            if (document.querySelector('input[name="phone"]').value.indexOf('_') > -1) {
                document.querySelector('input[name="phone"]').classList.add('error-input');
                sendAccess = false;
                // formResult.textContent = 'Корректно заполните ваш телефон';
                // formResult.classList.add('show');
                // return false;
            } else {
                sendObject.phone = document.querySelector('input[name="phone"]').value;
            }
        } else {
            document.querySelector('input[name="phone"]').classList.add('error-input');
            sendAccess = false;
            // formResult.textContent = 'Корректно заполните ваш телефон';
            // formResult.classList.add('show');
            // return false;
        }

        if (document.querySelector('input[name="description"]').value.length > 0) {
            sendObject.description = document.querySelector('input[name="description"]').value;
        }


        let id = '';
        if (getCookie('uid')) {
            id = getCookie('uid');
        } else {
            let number = Math.random() // 0.9394456857981651
            number.toString(36); // '0.xtis06h6'
            let id = number.toString(36).substr(2, 9); // 'xtis0
            document.cookie = 'uid=' + id;
        }

        sendObject.id = id;
        sendObject.method = 'vacancy';

        if (document.getElementsByClassName('brief__task-files__item').length > 0) {
            sendObject.files = [];
            document.querySelectorAll('.brief__task-files__item').forEach((element) => sendObject.files.push(element.getAttribute('data-url')))
        }

        if (sendAccess) {
            let xhr = new XMLHttpRequest();
            xhr.open("POST", '/ajax.php', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    let data = JSON.parse(xhr.responseText);
                    if (data.success) {
                        document.getElementsByClassName('brief__result-shadow')[0].classList.add('showed');
                    } else {
                        formResult.textContent = 'Произошла ошибка, попробуйте позже';
                        formResult.classList.add('show');
                    }
                } else {
                    formResult.textContent = 'Произошла ошибка, попробуйте позже';
                    formResult.classList.add('show');
                }
            };
            xhr.send('param=' + JSON.stringify(sendObject));
        } else {
            let target = document.getElementsByClassName("error-input")[0];
            animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 200, 300, true);
        }
    }

    photoUpload = async () => {
        let photos = document.getElementById("resume-file").files;
        let formData = new FormData();
        if (photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
                formData.append("files[]", photos[i])
            }
        }
        formData.append('method', 'files');

        let id = '';
        if (getCookie('uid')) {
            id = getCookie('uid');
        } else {
            let number = Math.random()
            number.toString(36);
            let id = number.toString(36).substr(2, 9);
            document.cookie = 'uid=' + id;
        }
        formData.append('id', id);
        await fetch('/ajax.php', {method: "POST", body: formData}).then((response) => {
            return response.json();
        }).then((data) => {
            document.getElementById("resume-file").value = "";
            if (data.files.length > 0) {
                for (let i = 0; i < data.files.length; i++) {
                    document.getElementsByClassName('brief__task-files__list')[0].insertAdjacentHTML(
                        'beforeend', '<div class="brief__task-files__item" data-url="' + data.files[i].url + '">' + data.files[i].name + '</div>'
                    );
                }
            }
        });
    }

    render() {
        return(
            <section className="vacancy__page">
                <div className="wrapper">
                    <h1 className="brief__title h1">Откликнуться на вакансию</h1>
                    <div className="brief__description text">
                        Оставьте свои контактные данные и прикрепите файл с резюме. Мы обязательно свяжемся с вами.
                    </div>
                    <div className="brief__box vacancy-brief">
                        <div className="brief__box-contacts">
                            <div className="brief__box-contact__item">
                                <input type="text" name="fio" placeholder="Имя и Фамилия" className="brief__box-input"/>
                            </div>
                            <div className="brief__box-contact__item">
                                <input type="text" name="city" placeholder="Город" className="brief__box-input"/>
                            </div>
                            <div className="brief__box-contact__item">
                                <input type="text" name="email" placeholder="E-mail" className="brief__box-input"/>
                            </div>
                            <div className="brief__box-contact__item">
                                <InputMask className="brief__box-input" name="phone" placeholder="Телефон" mask="+7 999 999 99 99" />
                            </div>
                            <div className="brief__box-contact__description">
                                <input type="text" name="description" placeholder="Описание или ссылка на проект" className="brief__box-input"/>
                            </div>
                            <div className="brief__box-file">
                                <div className="brief__task-files">
                                    <div className="brief__task-files__list"/>
                                    <input type="file" id="resume-file" onChange={this.photoUpload.bind(this)} multiple={true}/>
                                    <label htmlFor="resume-file" className="brief__task-file">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.15167 13.9332C1.88337 14.6649 2.85737 15.0846 3.82394 15.0846C4.63676 15.0846 5.38222 14.7867 5.92304 14.246L12.2472 7.92174C12.6394 7.53407 12.8331 6.99416 12.7913 6.40307C12.7097 5.2497 11.6664 4.20649 10.5131 4.12481C10.4611 4.12116 10.4087 4.11926 10.3573 4.11926C9.82888 4.11926 9.34364 4.3157 8.99105 4.67232L3.4608 10.2026C3.28307 10.3803 3.19219 10.6328 3.21142 10.8953C3.22949 11.1419 3.34034 11.3785 3.52333 11.5615C3.72324 11.7615 3.99176 11.8761 4.26002 11.8761C4.49884 11.8761 4.71986 11.7866 4.88242 11.6241L10.4161 6.09043C10.4471 6.05913 10.4862 6.04445 10.5368 6.04445C10.5427 6.04445 10.5487 6.04463 10.5545 6.04507C10.6997 6.05571 10.8604 6.21645 10.8712 6.36343C10.8738 6.40155 10.8687 6.45744 10.8287 6.49699L4.50145 12.8242C4.28507 13.0406 3.98156 13.1598 3.64677 13.1598C3.2175 13.1598 2.78072 12.9691 2.44824 12.6367C2.1426 12.331 1.95823 11.9434 1.92909 11.5451C1.90135 11.1666 2.01909 10.825 2.26066 10.5834L9.37533 3.46871C9.78091 3.06315 10.3467 2.83979 10.9684 2.83979C11.7467 2.83979 12.5363 3.183 13.1347 3.78143C14.2573 4.9041 14.3976 6.59056 13.4474 7.54076L7.91374 13.0744C7.73601 13.2521 7.64513 13.5046 7.66437 13.7671C7.68243 14.0137 7.79328 14.2504 7.97627 14.4334C8.17618 14.6333 8.44471 14.7479 8.71299 14.7479C8.95182 14.7479 9.17281 14.6584 9.33537 14.496L14.869 8.96229C16.5341 7.2972 16.3377 4.39138 14.4311 2.48478C13.4335 1.48716 12.1069 0.915039 10.7915 0.915039V1.09394L10.7914 0.915039C9.69123 0.915127 8.68362 1.31712 7.95367 2.04703L0.839042 9.16165C0.237133 9.76359 -0.0574929 10.597 0.0093014 11.5088C0.0746049 12.4009 0.480369 13.2619 1.15167 13.9332Z" fill="currentColor"/>
                                        </svg>
                                        <span>Прикрепить файл</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="brief__submit">
                        <button className="brief__submit-button" onClick={this.sendVacancyData.bind(this)}>Отправить резюме</button>
                        <div className="brief-privacy">
                            Нажимая на кнопку, вы даете <a href="/Personal.pdf" target="_blank" title="Согласие на обработку персональных данных">согласие</a> на обработку персональных данных и соглашаетесь с политикой конфиденциальности.
                        </div>
                    </div>
                    <div className="brief__result">
                        Заполните ваше имя
                    </div>
                </div>
                <div className="brief__result-shadow">
                    <div className="brief__result-modal">
                        <div className="wrapper">
                            <div className="brief__result-box">
                                <div className="brief__result-content">
                                    <div className="brief__result-title h3">Анкета отправлена</div>
                                    <div className="brief__result-description text">
                                        Ожидайте, скоро мы с вами свяжемся, а пока можете посмотреть наши работы
                                    </div>
                                </div>
                                <div className="brief__result-link">
                                    <RightArrowBtn text="В порфолио" link="/works"/>
                                </div>
                                <button className="brief__result-close">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8323 9.99994L19.6199 2.21211C20.1267 1.70554 20.1267 0.886491 19.6199 0.379925C19.1133 -0.126642 18.2943 -0.126642 17.7877 0.379925L9.99988 8.16776L2.21228 0.379925C1.70548 -0.126642 0.886669 -0.126642 0.380103 0.379925C-0.126701 0.886491 -0.126701 1.70554 0.380103 2.21211L8.1677 9.99994L0.380103 17.7878C-0.126701 18.2943 -0.126701 19.1134 0.380103 19.62C0.632555 19.8726 0.964493 19.9996 1.29619 19.9996C1.62789 19.9996 1.95959 19.8726 2.21228 19.62L9.99988 11.8321L17.7877 19.62C18.0404 19.8726 18.3721 19.9996 18.7038 19.9996C19.0355 19.9996 19.3672 19.8726 19.6199 19.62C20.1267 19.1134 20.1267 18.2943 19.6199 17.7878L11.8323 9.99994Z" fill="black"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const getCookie = name => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

const animate = (elem, style, unit, from, to, time, prop) => {
    if (!elem) {
        return;
    }
    let start = new Date().getTime(), timer = setInterval(function () {
        let step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
            elem[style] = (from + step * (to - from))+unit;
        } else {
            elem.style[style] = (from + step * (to - from))+unit;
        }
        if (step === 1) {
            clearInterval(timer);
        }
    }, 25);

    if (prop) {
        elem[style] = from+unit;
    } else {
        elem.style[style] = from+unit;
    }
}

export default VacancyPage;