import React from 'react';
import Product from "../components/Product";
import PageBottomBanner from "../components/PageBottomBanner";
import {animate} from "../pages/Home";
import {Helmet} from "react-helmet";

const productsMain = [
    {
        'img': '/_images/zavarin_cover.jpg',
        'video': '/_video/zavarin_review.mp4',
        'tags': 'Брендинг / Дизайн / Разработка / Автоматизация',
        'name': 'Ювелирный дом ЗАВАРИН',
        'link': 'http://zavarin.art/',
        'link_text': 'zavarin.art',
        'description': 'Разработка логотипа и фирменного стиля для ювелирного производства из Костромы. Проектирование и дизайн интернет-магазина, автоматизация каталога с 1С Торговля.',
        'types': [
            'ecom', 'manufacture'
        ],
        'services': [
            { 'name': 'Брендинг', 'value': 'brand' },
            { 'name': 'Дизайн', 'value': 'design' },
            { 'name': 'Разработка', 'value': 'development' },
            { 'name': 'Автоматизация', 'value': 'automation' },
        ]
    },
    {
        'img': '/_images/polimer_cover.jpg',
        'video': '/_video/polimer_review.mp4',
        'tags': 'Дизайн / Разработка / Реклама / Поисковая оптимизация',
        'name': 'Полимерстройсервис',
        'link': 'https://psskos.ru/',
        'link_text': 'psskos.ru',
        'description': 'Создание сайта регионального производителя стальных и межкомнатных дверей Полиместройсервис. Разработка конфигуратора дверей для автоматизации оформления заказов в салонах продаж.',
        'types': [
            'ecom', 'manufacture'
        ],
        'services': [
            { 'name': 'Дизайн', 'value': 'design' },
            { 'name': 'Разработка', 'value': 'development' },
            { 'name': 'Реклама', 'value': 'advertising' },
            { 'name': 'Поисковая оптимизация', 'value': 'seo' },
        ]
    },
    {
        'img': '/_images/millenium_cover.jpg',
        'video': '/_video/millenium_review.mp4',
        'tags': 'Поддержка / Реклама / Поисковая оптимизация',
        'name': 'Официальный дилер ŠKODA Миллениум Авто',
        'link': 'https://millenium-avto.ru/',
        'link_text': 'millenium-avto.ru',
        'description': 'Поддержка и SEO-оптимизация дилерской платформы ŠKODA Digital Platform. Настройка контекстной рекламы для дилерского центра.',
        'types': [
            'auto'
        ],
        'services': [
            { 'name': 'Поддержка', 'value': '' },
            { 'name': 'Реклама', 'value': 'advertising' },
            { 'name': 'Поисковая оптимизация', 'value': 'seo' },
        ]
    },
    {
        'img': '/_images/autoclimat_cover.jpg',
        'video': '/_video/autoclimat_review.mp4',
        'tags': 'Дизайн / Разработка / Поисковая оптимизация',
        'name': 'Автомастерская “Автоклимат”',
        'link': 'https://autoklimat44.ru/',
        'link_text': 'autoklimat44.ru',
        'description': 'Создание интернет-магазина по подбору запчастей климатического оборудования Refco, Mastercool, Testo, CPS, Veirol, U-NIT.',
        'types': [
            'auto', 'ecom', 'manufacture'
        ],
        'services': [
            { 'name': 'Дизайн', 'value': 'design' },
            { 'name': 'Разработка', 'value': 'development' },
            { 'name': 'Поисковая оптимизация', 'value': 'seo' },
        ]
    }
]

class Works extends React.Component {
    componentDidMount() {
        // document.title = `Наши работы`;

        const products = document.getElementsByClassName("product__item");

        const elements = document.getElementsByClassName("works__filter-tab");
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', (e) => {
                e.preventDefault();
                if (elements[i].classList.contains('active')) return;
                [].forEach.call(elements, el => {
                    el.classList.remove("active");
                });
                elements[i].classList.add('active');
                let sectionId = elements[i].getAttribute('data-id');
                const sections = document.getElementsByClassName("works__filter-section");
                [].forEach.call(sections, el => {
                    if (el.getAttribute('data-id') !== sectionId) {
                        el.classList.remove("active");
                    } else {
                        el.classList.add("active");
                    }
                });
            })
        }

        const filters = document.getElementsByClassName("works__filter-type");
        for (let i = 0; i < filters.length; i++) {
            filters[i].addEventListener('click', (e) => {
                e.preventDefault();
                if (filters[i].classList.contains('active')) return;
                [].forEach.call(filters, el => {
                    el.classList.remove("active");
                });
                filters[i].classList.add('active');

                for (let j = 0; j < products.length; j++) {
                    products[j].classList.remove('filter-hide');
                }

                if (filters[i].getAttribute('data-branch') != null) {
                    for (let j = 0; j < products.length; j++) {
                        if (filters[i].getAttribute('data-branch') !== 'all') {
                            let productTypes = products[j].getAttribute('data-types').split(';');
                            if (productTypes.includes(filters[i].getAttribute('data-branch'))) {
                                products[j].classList.remove('filter-hide');
                            } else {
                                products[j].classList.add('filter-hide');
                            }
                        } else {
                            products[j].classList.remove('filter-hide');
                        }
                    }
                }

                if (filters[i].getAttribute('data-typework') !== null) {
                    for (let j = 0; j < products.length; j++) {
                        if (filters[i].getAttribute('data-typework') !== 'all') {
                            let productServices = products[j].getAttribute('data-services').split(';');
                            if (productServices.includes(filters[i].getAttribute('data-typework'))) {
                                products[j].classList.remove('filter-hide');
                            } else {
                                products[j].classList.add('filter-hide');
                            }
                        } else {
                            products[j].classList.remove('filter-hide');
                        }
                    }
                }
            });
        }

        const filterProductItems = document.getElementsByClassName('product__item-tag');
        for (let i = 0; i < filterProductItems.length; i++) {
            filterProductItems[i].addEventListener('click', (e) => {
                e.preventDefault();
                if (filterProductItems[i].getAttribute('data-id').length > 0) {
                    for (let j = 0; j < products.length; j++) {
                        let productServices = products[j].getAttribute('data-services').split(';');
                        if (productServices.includes(filterProductItems[i].getAttribute('data-id'))) {
                            products[j].classList.remove('filter-hide');
                        } else {
                            products[j].classList.add('filter-hide');
                        }
                    }
                    let target = document.getElementsByClassName("works__filter")[0];
                    document.querySelectorAll('.works__filter-type').forEach((filter) => {filter.classList.remove('active')});
                    document.querySelector('.works__filter-type[data-typework="' + filterProductItems[i].getAttribute('data-id') + '"]').classList.add('active');
                    document.querySelector('.works__filter-section.active').classList.remove('active');
                    document.querySelector('.works__filter-type[data-typework="' + filterProductItems[i].getAttribute('data-id') + '"]').parentNode.classList.add('active');
                    document.querySelector('.works__filter-tab.active').classList.remove('active');
                    document.querySelector('.works__filter-tab[data-id="type"]').classList.add('active');
                    animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - document.getElementsByClassName('header__place')[0].clientHeight - 30, 400, true);
                }
            });
        }
    }

    render() {
        return (
            <section className="work__page">
                <Helmet>
                    <title>Наши работы | Интернет-лаборатория Пластилин</title>
                    <meta name="description" content="В данном разделе собраны успешные кейсы по продвижению сайтов. Мы работаем с представитями различных отраслей бизнеса. ★ Интернет-лаборатория Пластилин" />
                </Helmet>
                <div className="wrapper">
                    <h1 className="works__title h1">Наши работы</h1>
                    <div className="works__description text">
                        Мы действительно любим проектировать сайты, работать с рекламой и заниматься развитием электронной коммерции, ценим мнение наших клиентов и их решение сотрудничать с нами.
                    </div>
                    <div className="works__filter">
                        <div className="works__filter-tabs">
                            <a href="#" className="works__filter-tab active" data-id="branch" title="По отрасли">По отрасли</a>
                            <a href="#" className="works__filter-tab" data-id="type" title="По виду работ">По виду работ</a>
                        </div>
                        <div className="works__filter-sections">
                            <div className="works__filter-section active" data-id="branch">
                                <a href="#" className="works__filter-type" data-branch="all" title="Все">Все</a>
                                <a href="#" className="works__filter-type" data-branch="auto" title="Авто">Авто</a>
                                <a href="#" className="works__filter-type" data-branch="manufacture" title="Производство">Производство</a>
                                <a href="#" className="works__filter-type" data-branch="ecom" title="E-commerce">E-commerce</a>
                            </div>
                            <div className="works__filter-section" data-id="type">
                                <a href="#" className="works__filter-type" data-typework="all" title="Все">Все</a>
                                <a href="#" className="works__filter-type" data-typework="brand" title="Брендинг">Брендинг</a>
                                <a href="#" className="works__filter-type" data-typework="design" title="Дизайн">Дизайн</a>
                                <a href="#" className="works__filter-type" data-typework="development" title="Разработка">Разработка</a>
                                <a href="#" className="works__filter-type" data-typework="advertising" title="Реклама">Реклама</a>
                                <a href="#" className="works__filter-type" data-typework="seo" title="Поисковая оптимизация">Поисковая оптимизация</a>
                                <a href="#" className="works__filter-type" data-typework="automation" title="Автоматизация">Автоматизация</a>
                            </div>
                        </div>
                    </div>
                    <div className="works__products">
                        {
                            productsMain.map((product, i) => {
                                return (<Product product={product} key={`product_page_` + i} id={`product_page_` + i} page/>);
                            })
                        }
                    </div>
                </div>
                <PageBottomBanner services/>
            </section>
        );
    }
}
export default Works;