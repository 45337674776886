import React from 'react';
import { withRouter } from 'react-router-dom';


class Metrics extends React.Component {
    componentWillUpdate ({ location, history }) {
        if (location.pathname === this.props.location.pathname) {
            return;
        }
        const ym = window.ym, gtag = window.gtag;
        if (history.action === 'PUSH' && typeof ym === 'function') {
            ym(29279650, 'hit', window.location.href);
        }
        if (history.action === 'PUSH' &&
            typeof(gtag) === 'function') {
            gtag('set', 'page', location.pathname);
            gtag('send', 'pageview');
        }
        // if (history.action === 'PUSH' &&
        //     typeof(gtag) === 'function') {
        //     gtag('config', GA_TRACKING_ID, {
        //         'page_title': document.title,
        //         'page_location': window.location.href,
        //         'page_path': location.pathname
        //     });
        // }
    }

    render () {
        return null;
    }
}

export default withRouter(Metrics);