import React, {useEffect} from 'react';
import PageTopBanner from "../components/PageTopBanner";
import KeypointItem from "../components/KeypointItem";
import PageBottomBanner from "../components/PageBottomBanner";
import {Helmet} from "react-helmet";

const keypointItems = [
    {
        'img': 'brief',
        'title': 'Брифинг',
        'description': 'Сбор ключевой вводной информации от продуктологов, маркетологов и  руководителей о процессах и текущем положении бизнеса.'
    },
    {
        'img': 'metric',
        'title': 'Анализ метрик',
        'description': 'Выделение целевой аудитории, каналов ее привлечения. Анализ повторных покупок и частоты обращений клиентов, выделение особенностей поведенческого фактора.'
    },
    {
        'img': 'competitor_analysis',
        'title': 'Анализ конкурентов, поиск удачных решений для применения',
        'description': 'Выделение сильных и слабых сторон фунционала, дизайна, объема данных конкурентов. Адаптация и применение успешных практик в новом проекте.'
    },
    {
        'img': 'audit',
        'title': 'Аудит текущего сайта, формирование запроса по контенту',
        'description': 'Анализ пользовательского поведения на основе текущих данных. Формирование требований по улучшению структуры, дизайна и контента сайта.'
    },
]

const Analytics = () => {
    useEffect(() => {
        // document.title = `Предпроектная аналитика`;
    });

    return (
        <section className="analytics__page">
            <Helmet>
                <title>Web-аналитика сайта | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="Закажите проектную Web-аналитику для оценки эффективности вашего сайта ✔ Брифинг ✔ Аналитика вашего проекта и конкурентов ✔ Анализ ключевых показателей ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <PageTopBanner analytics/>
            <div className="service-detail">
                <div className="wrapper">
                    <div className="service-detail__text">
                        <div className="service-detail__title h3">Зачем нужна аналитика в проекте?</div>
                        <p>
                            Все мы знаем, что правильная постановка задачи — это половина ее решения. Однако насколько важен результат поставленной задачи, если при этом делается не то, что нужно? Подавляющее число обсуждений сводится к задачам внутри проекта, при этом в серой зоне оказывается переход от осознания, что делается, зачем, а также каким способом.
                        </p>
                        <p>
                            Понимание полного состава задач перед началом работы помогает избежать проблем, игнорирование которых обрекает в лучшем случае на отказ от запуска, в худшем — на мучения в заведомо провальном проекте.
                        </p>
                        <p>Задачи предпроектной аналитики:</p>
                        <ul>
                            <li>- сделать реализацию проекта на основе конкретных фактов, а не субъективного опыта;</li>
                            <li>- определить целевую аудиторию и возможности дальнейшего развития;</li>
                            <li>- собрать информацию о ситуации на рынке, преимуществах сайтов конкурентов;</li>
                            <li>- построить план дальнейшего развития, а также рисков на всех последующих этапах.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="service__keys">
                <div className="wrapper">
                    <div className="service__keys-title">Разделы анализа</div>
                    <div className="keys__items">
                        {
                            keypointItems.map((keypoint, i) => {
                                return (<KeypointItem keypoint={keypoint} key={`keypoint_main_` + i}/>);
                            })
                        }
                    </div>
                </div>
            </div>
            <PageBottomBanner services analytics/>
        </section>
    );
}

export default Analytics;