import React from 'react';
import VideoPlayer from "../components/VideoPlayer";

const Product = (params) => {
    const product = params.product;

    let productTypes = '', productServices = '';
    if (product.types !== undefined) {
        productTypes = product.types.join(';');
    }
    if (product.services !== undefined) {
        let _temp = [];
        product.services.forEach((element) => _temp.push(element.value))
        productServices = _temp.join(';');
    }
    return (
        <div className="product__item" data-types={productTypes} data-services={productServices}>
            <div className="product__item-img">
                <VideoPlayer product={product} id={params.id}/>
            </div>
            <div className="product__item-tags">
                {!params.page && product.tags}
                {
                    params.page && product.services.map((service, i) => {
                        return (<a href="#" className="product__item-tag" data-id={service.value}>{service.name}</a>);
                    })
                }
            </div>
            <div className="product__item-name h3">{product.name}</div>
            <a href={product.link} target="_blank" rel="noopener noreferrer" className="product__item-link" title={product.link_text}>{product.link_text}</a>
            <div className="product__item-description text">{product.description}</div>
        </div>
    );
}

export default Product;