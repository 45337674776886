import React, {useEffect} from 'react';
import PageTopBanner from "../components/PageTopBanner";
import KeypointItem from "../components/KeypointItem";
import PageBottomBanner from "../components/PageBottomBanner";
import {Helmet} from "react-helmet";

const keypointItems = [
    {
        'img': 'magnet',
        'title': 'Анализ спроса, изучение цепочки продажи',
        'description': 'Разбираем продукт, суть и характер продажи, средний чек. Изучаем спрос на поиске, оцениваем работу и готовность площадки обработать трафик.'
    },
    {
        'img': 'money',
        'title': 'Контроль и аналитика расходов',
        'description': 'Управляем рекламным бюджетом так, чтобы вы платили только за целевых посетителей. Следим за выполнением плановых показателей.'
    },
    {
        'img': 'semantic',
        'title': 'Сбор семантики, настройка кампании',
        'description': 'Пользуемся Яндекс.Вордстат, но и не забываем про базы сбора семантики. Используем инструменты аналитики и автоматизации eLama, K50.'
    },
    {
        'img': 'statistic',
        'title': 'Подготовка статистики и отчетов',
        'description': 'Формируем индивидуальные KPI и ежемесячно отчитываемся о проделанной работе. Используем эти данные для корректировки стратегии в будущем.'
    },
]

const Placement = () => {
    useEffect(() => {
        // document.title = `Реклама на поиске и медийных сетях`;
    });

    return (
        <section className="placement__page">
            <Helmet>
                <title>Настройка контекстной рекламы | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="Закажите качественную настройку контекстной рекламы для вашего сайта ✔ Анализ спроса ✔ Настройка рекламной компании ✔ Контроль и аналитика расходов ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <PageTopBanner advertising/>
            <div className="service-detail">
                <div className="wrapper">
                    <div className="service-detail__text">
                        <div className="service-detail__title h3">Реклама, настроенная на результат</div>
                        <p>
                            У вас сложные цели, для решения которых нет очевидного алгоритма? Формирование бренда, выход на новый рынок, повышение конверсии из лида в продажу, увеличение среднего чека, увеличение объёмов продаж со снижением стоимости привлечения — вот краткий список задач, с которыми мы поможем вам справиться.
                        </p>
                        <p>
                            Контекстная реклама - это прежде всего нацеленность на результат, достижение которого невозможно без комплексного подхода.  Наш подход отличается от стандартной настройки объявлений и подбора ключевых фраз.
                        </p>
                        <p>
                            В работе с клиентом мы стараемся:
                        </p>
                        <ul>
                            <li>- объяснять рациональность использования тех или иных KPI; </li>
                            <li>- откровенно и аргументированно указывать на проблемы с сайтом; </li>
                            <li>- предлагать инструменты для улучшения конверсии;</li>
                            <li>- периодически проверять качество обработки заявок;</li>
                            <li>- интегрировать систему внутреннего учета заявок с системой аналитики.</li>
                        </ul>
                        <p>
                            Наши клиенты разделяют ответственность за результат вместе с нами. Именно поэтому на этапе формирования рекламной стратегии мы оцениваем выполнимость поставленной цели. Если цель недостижима, мы прямо скажем об этом. Причиной отказа от запуска рекламы может стать:
                        </p>
                        <ul>
                            <li>- недостаточность рекламного бюджета;</li>
                            <li>- сомнительное качество продукта (не продаёт сам себя);</li>
                            <li>- текущий целевой трафик на сайт не конвертируется в клиентов;</li>
                            <li>- отсутствие «цифрового отдел продаж» (диалог с потенциальным клиентом происходит только через оффлайн).</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="service__keys">
                <div className="wrapper">
                    <div className="service__keys-title">Направления работы</div>
                    <div className="keys__items">
                        {
                            keypointItems.map((keypoint, i) => {
                                return (<KeypointItem keypoint={keypoint} key={`keypoint_main_` + i}/>);
                            })
                        }
                    </div>
                </div>
            </div>
            <PageBottomBanner services placement/>
        </section>
    );
}

export default Placement;