import React from 'react'
import Lottie from 'react-lottie'
import seoAnimationData from '../animationJson/seo.json'
import webAnimationData from '../animationJson/web2.json'
import analyticsData from '../animationJson/analytics.json'
import serviceBottomData from '../animationJson/service_bottom_banner.json'
import workBottomData from '../animationJson/work.json'
import errorData from '../animationJson/error.json'
import homeData from '../animationJson/home.json'
import placementNew from '../animationJson/placement_new.json';

class BannerAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isStopped: false, isPaused: false};
    }
    render() {
        let animationData = {};
        if (this.props.home) {
            animationData = homeData;
        }
        if (this.props.seo) {
            animationData = seoAnimationData;
        }
        if (this.props.web) {
            animationData = webAnimationData;
        }
        if (this.props.analytics) {
            animationData = analyticsData;
        }
        if (this.props.advertising) {
            animationData = placementNew;
        }
        if (this.props.services) {
            animationData = serviceBottomData;
        }
        if (this.props.work) {
            animationData = workBottomData;
        }
        if (this.props.error) {
            animationData = errorData;
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
        };

        return <Lottie options={defaultOptions} isStopped={this.state.isStopped} isPaused={this.state.isPaused}/>
    }
}

export default BannerAnimation;