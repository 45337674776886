import React, {useEffect} from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import RightArrowBtn from "../components/RightArrowBtn";
import PageBottomBanner from "../components/PageBottomBanner";
import {Helmet} from "react-helmet";

const About = () => {
    useEffect(() => {
        // document.title = `О нас - Интернет-лаборатория "Пластилин"`;

        const anchor = window.location.hash;
        if (anchor) {
            const domElement = document.querySelector(anchor);
            if (domElement) {
                domElement.scrollIntoView();
            }
        }
    });

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    return (
        <section className="career__page">
            <Helmet>
                <title>Наш офис | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="Наш офис находится в самом центре Костромы. Здесь мы делимся своими идеями с клиентами и коллегами ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <div className="wrapper">
                <h1 className="career__title h1">Наш офис</h1>
                <div className="career__description text">
                    Наш уютный офис находится в самом центре небольшого города Кострома. В нем мы стараемся сделать процесс работы максимально простым, автоматизируя задачи и оставаясь открытыми творческим идеям
                </div>
                <div className="career__slider">
                    <Slider {...settings}>
                        <img src="/_images/3-min.jpg" alt="" />
                        <img src="/_images/1-min.jpg" alt="" />
                        <img src="/_images/2-min.jpg" alt="" />
                        <img src="/_images/4-min.jpg" alt="" />
                    </Slider>
                </div>
                <div id="vacancy">
                    <h2 className="vacancy__title">Открытые вакансии</h2>
                    <div className="vacancy__description text">
                        Каждый сотрудник проходит два этапа отбора. Первый — тестирование, на котором оценивается опыт работы и уровень квалификации соискателя. Второй — знакомство с принципами работы агентства, выполнение поставленных задач на этапе испытательного периода.
                    </div>
                    <div className="vacancy__list">
                        <div className="vacancy__item">
                            <div className="vacancy__item-title h3">Frontend React-разработчик</div>
                            <div className="vacancy__item-requirement text">
                                <div>Требования</div>
                                <p>— React;</p>
                                <p>— опыт работы с Next.js;</p>
                                <p>— навыки конфигурации Webpack и Gulp;</p>
                                <p>— опыт разработки анимаций в интерфейсах;</p>
                                <p>— препроцессоры (Stylus, SASS или LESS);</p>
                                <p>— умение работать с Canvas;</p>
                                <p>— опыт backend-разработки.</p>
                            </div>
                            <div className="vacancy__item-work text">
                                <div>Чем придется заниматься</div>
                                <p>— проектирование архитектуры веб-продуктов;</p>
                                <p>— разработка сайтов и сервисов;</p>
                                <p>— развитие и поддержка существующих сайтов.</p>
                            </div>
                            <div className="vacancy__item-button">
                                <RightArrowBtn text="Откликнуться" link="/vacancy/frontend"/>
                            </div>
                        </div>
                        <div className="vacancy__item">
                            <div className="vacancy__item-title h3">Интернет-маркетолог</div>
                            <div className="vacancy__item-requirement text">
                                <div>Требования</div>
                                <p>— умение работать с контекстной рекламой (ЯД, GA);</p>
                                <p>— умение работать с графическим и текстовым контентом;</p>
                                <p>— умение работать с системами аналитики (ЯМ, GA);</p>
                                <p>— опыт работы с контекстной рекламой;</p>
                                <p>— внимательность к деталям;</p>
                                <p>— умение работать в режиме многозадачности.</p>
                            </div>
                            <div className="vacancy__item-work text">
                                <div>Чем придется заниматься</div>
                                <p>— работа с инструментами продвижения;</p>
                                <p>— разработка контент-плана;</p>
                                <p>— подготовка рекламных кампаний на поиске и в КМС Яндекс и Google;</p>
                                <p>— разработка и запуск таргетированной рекламы в социальных сетях;</p>
                                <p>—  составление ТЗ и реализация работ с командой (дизайн, фото, видео).</p>
                            </div>
                            <div className="vacancy__item-button">
                                <RightArrowBtn text="Откликнуться" link="/vacancy/marketer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageBottomBanner career/>
        </section>
    );
}
export default About;