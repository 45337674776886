import React from 'react';

class CookieWarning extends React.Component {
    setCookie = (name,value,days) => {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    getCookie = name => {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    componentDidMount() {
        if (document.getElementsByClassName('cookie-warning__button').length > 0) {
            document.querySelector('.cookie-warning__button').addEventListener('click', (e) => {
                this.setCookie('closed_warning', 'Y', 365);
                document.getElementsByClassName('cookie-warning__wrapper')[0].classList.add('hidden');
            });
        }
    }

    render() {
        if (this.getCookie('closed_warning') !== null) {
            return <div/>
        } else {
            return (
                <div className="cookie-warning__wrapper">
                    <div className="wrapper">
                        <div className="cookie-warning">
                            <div className="cookie-warning__text">
                                Мы используем файлы cookie в соответствии с политикой в отношении файлов cookie, чтобы обеспечить лучшую работу с сайтом.
                            </div>
                            <button className="cookie-warning__button">Хорошо</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CookieWarning;