import React, {useEffect} from 'react';
import PageTopBanner from "../components/PageTopBanner";
import KeypointItem from "../components/KeypointItem";
import PageBottomBanner from "../components/PageBottomBanner";
import {Helmet} from "react-helmet";

const keypointItems = [
    {
        'img': 'content',
        'title': 'Контент',
        'description': 'Тексты, фотографии и видеоролики, которые вопринимаются как единое целое, преподносят и раскрывают аудитории вашу историю и преимущества продукта.'
    },
    {
        'img': 'project',
        'title': 'Проектирование',
        'description': 'Один из ключевых этапов, на котором закладывается структура, маркетинг, интерфейс. Создается на основе анализа, технического задания и брифа.'
    },
    {
        'img': 'design',
        'title': 'Дизайн',
        'description': 'На этом этапе готовятся дизайн-макеты внешнего вида сайта, создается единая дизайн-система форм, блоков и элементов взаимодействия с пользователем.'
    },
    {
        'img': 'develop',
        'title': 'Front-end / back-end',
        'description': 'Создание визуальной оболочки снаружи и надежной архитектуры внутри. Отвечает за быстродействие, удобство работы и масштабируемость проекта в будущем.'
    },
]

const Web = () => {
    useEffect(() => {
        // document.title = `Веб-разработка`;
    });

    return (
        <section className="web__page">
            <Helmet>
                <title>Веб-разработка сайта | Интернет-лаборатория Пластилин</title>
                <meta name="description" content="Закажите Web-разработку вашего первого сайта и доработку старого на 1C Битрикс, React ✔ Согласование контента ✔ Проектирование и дизайн сайта ✔ Front-end / back-end ★ Интернет-лаборатория Пластилин" />
            </Helmet>
            <PageTopBanner web/>
            <div className="service-detail">
                <div className="wrapper">
                    <div className="service-detail__text">
                        <div className="service-detail__title h3">Разработка важный этап любого проекта</div>
                        <p>
                            Возможно, вы готовитесь создать новый сайт или собираетесь переделать старый. Тогда у вас есть отличная возможность сделать все правильно с самого начала. На этапе идеи важно понимать, что сайт, в первую очередь, инструмент, который имеет четко сформулированные цели. Создавать сайт “чтобы был” - бессмысленно.
                        </p>
                        <p>
                            До тех пор, пока нет плана развития проекта, не определен список задач для команды исполнителей, не стоит торопиться с разработкой. Мы всегда заранее обсуждаем с клиентами сценарии продвижения сайта перед началом работы, выделяем ключевые каналы привлечения трафика.
                        </p>
                        <p>
                            При сотрудничестве делим задачи на самостоятельные этапы. На каждом этапе даем конструктивный фидбэк, умеем прислушиваться к мнению клиента и его идеям - в большинстве случаев они оправданы и основаны на опыте работы в своей сфере.
                        </p>
                    </div>
                </div>
            </div>
            <div className="service__keys">
                <div className="wrapper">
                    <div className="service__keys-title">Этапы разработки</div>
                    <div className="keys__items">
                        {
                            keypointItems.map((keypoint, i) => {
                                return (<KeypointItem keypoint={keypoint} key={`keypoint_main_` + i}/>);
                            })
                        }
                    </div>
                </div>
            </div>
            <PageBottomBanner services web/>
        </section>
    );
}

export default Web;