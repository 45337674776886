import React from 'react';
import RightArrowBtn from "../components/RightArrowBtn";
import { NavLink } from 'react-router-dom';

import HomeBottom from '../svg/blog_voprosy.svg';
import CareerBottom from '../svg/career_bottom.svg';
import ServicesBottom from '../svg/services_bottom.svg';
import BannerAnimation from "./BannerAnimation";

const PageBottomBannerIcon = ({type}) => {
    switch (type) {
        case 'home':
            return <img src={HomeBottom} alt=''/>;
        case 'career':
            return <img src={CareerBottom} alt=''/>;
        case 'services':
            return <img src={ServicesBottom} alt=''/>;
        default:
            return '';
    }
}

class PageBottomBanner extends React.Component {
    render() {
        let subtitle = '', title = '', description = '', type='';
        if (this.props.home) {
            subtitle = 'SMM';
            title = 'Вопросы, которые раздражают в соцсетях';
            description = 'Порой в комментариях в соцсетях диалог покупателя с продавцом становится похож на диалог слепого с глухонемым, когда один из участников беседы не может донести свои коммуникативные намерения, а другой - просто не настроен на восприятие сообщения. Как избежать такой коммуникационный коллапс?';
            type = 'home';
        }
        if (this.props.career) {
            title = 'Почему работа в Пластилине?';
            description = 'Пластилин - это специалисты в области e-commerce, аналитики, разработки, маркетинга. Каждый сотрудник у нас, в первую очередь, — личность,  в которой соединены творчество, умение работать и нацеленность на результат';
            type = 'career';
        }
        if (this.props.services) {
            title = 'Давайте начнем работу прямо сейчас!';
            description = 'Оставьте заявку, заполнив контактные данные, и мы вместе обсудим ваш проект';
            type = 'services';

            if (this.props.placement) {
                title = 'Давайте настроим рекламу прямо сейчас!';
            }
            if (this.props.web) {
                title = 'Давайте спроектируем сайт вместе!';
            }
            if (this.props.analytics) {
                title = 'Давайте обсудим ваш проект!';
            }
            if (this.props.seo) {
                title = 'Давайте займёмся продвижением сайта!';
            }
        }
        if (type === 'home') {
            return (
                <div className="bottom-banner">
                    <div className="wrapper">
                        <NavLink to="/blog" className="bottom-banner__all-title h3">Блог</NavLink>
                        <NavLink to="/blog/questions" className="bottom-banner__link">
                            <div className="bottom-banner__box">
                                <div className="bottom-banner__image">
                                    <PageBottomBannerIcon type={type}/>
                                </div>
                                <div className="bottom-banner__content">
                                    { subtitle.length > 0 && <div className="bottom-banner__subtitle">{subtitle}</div>}
                                    <div className="bottom-banner__title">{title}</div>
                                    <div className="bottom-banner__description text">{description}</div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="bottom-banner">
                    <div className="wrapper">
                        <div className="bottom-banner__box">
                            <div className="bottom-banner__image">
                                {type === 'services' && <BannerAnimation services/>}
                                {type === 'career' && <BannerAnimation work/>}
                                {type === 'home' && <PageBottomBannerIcon type={type}/>}
                            </div>
                            <div className="bottom-banner__content">
                                { subtitle.length > 0 && <div className="bottom-banner__subtitle">{subtitle}</div>}
                                <div className="bottom-banner__title">{title}</div>
                                <div className="bottom-banner__description text">{description}</div>
                                {
                                    this.props.services && <RightArrowBtn text="Оставить заявку" link="/brief"/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default PageBottomBanner;