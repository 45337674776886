import React from 'react';
import {NavLink} from "react-router-dom";

import AnalyticsIcon from '../svg/keypoints/analitycs.svg'
import AdvertisingIcon from '../svg/keypoints/direct.svg'
import WebIcon from '../svg/keypoints/front-back.svg'
import SeoIcon from '../svg/keypoints/seo.svg'
import MagnetIcon from '../svg/keypoints/magnet.svg'
import MoneyIcon from '../svg/keypoints/money_control.svg'
import SemanticIcon from '../svg/keypoints/semantic.svg'
import StatisticIcon from '../svg/keypoints/statistic.svg'
import BriefIcon from '../svg/keypoints/brief.svg'
import MetricIcon from '../svg/keypoints/metric.svg'
import CompetitorIcon from '../svg/keypoints/competitor_analysis.svg'
import AuditIcon from '../svg/keypoints/audit.svg'
import ContentIcon from '../svg/keypoints/content.svg'
import ProjectIcon from '../svg/keypoints/project.svg'
import DesignIcon from '../svg/keypoints/design.svg'
import DevelopIcon from '../svg/keypoints/develop.svg'
import FirstAuditIcon from '../svg/keypoints/first_audit.svg'
import ExternalOptiIcon from '../svg/keypoints/external_optimization.svg'
import InnerOptiIcon from '../svg/keypoints/inner_optimazation.svg'

import AnalyticsHeaderIcon from '../svg/keypoints/analitycs_header.svg'
import AdvertisingHeaderIcon from '../svg/keypoints/direct_header.svg'
import WebHeaderIcon from '../svg/keypoints/front-back_header.svg'
import SeoHeaderIcon from '../svg/keypoints/seo_header.svg'

const KeypointIcon = (type) => {
    switch (type) {
        case 'analytics':
            return AnalyticsIcon;
        case 'advertising':
            return AdvertisingIcon;
        case 'web':
            return WebIcon;
        case 'search':
            return SeoIcon;
        case 'magnet':
            return MagnetIcon;
        case 'money':
            return MoneyIcon;
        case 'semantic':
            return SemanticIcon;
        case 'statistic':
            return StatisticIcon;
        case 'brief':
            return BriefIcon;
        case 'metric':
            return MetricIcon;
        case 'competitor_analysis':
            return CompetitorIcon;
        case 'audit':
            return AuditIcon;
        case 'content':
            return ContentIcon;
        case 'project':
            return ProjectIcon;
        case 'design':
            return DesignIcon;
        case 'develop':
            return DevelopIcon;
        case 'first_audit':
            return FirstAuditIcon;
        case 'external_optimization':
            return ExternalOptiIcon;
        case 'inner_optimization':
            return InnerOptiIcon;
        case 'analytics_header':
            return AnalyticsHeaderIcon;
        case 'advertising_header':
            return AdvertisingHeaderIcon;
        case 'web_header':
            return WebHeaderIcon;
        case 'search_header':
            return SeoHeaderIcon;
        default:
            return '';
    }
}

const KeypointItem = (params) => {
    const item = params.keypoint;
    if (typeof item.link !== "undefined") {
        return (
            <NavLink to={item.link} className="keys__item" title={item.title} onClick={params.onClick}>
                <div className="keys__item-icon">
                    <img src={KeypointIcon(item.img)} alt={item.title} />
                </div>
                <div className="keys__item-content">
                    <div className="keys__item-name h3">{item.title}</div>
                    <div className="keys__item-description text">{item.description}</div>
                </div>
            </NavLink>
        );
    } else {
        return (
            <div className="keys__item" onClick={params.onClick}>
                <div className="keys__item-icon">
                    <img src={KeypointIcon(item.img)} alt={item.title} />
                </div>
                <div className="keys__item-content">
                    <div className="keys__item-name h3">{item.title}</div>
                    <div className="keys__item-description text">{item.description}</div>
                </div>
            </div>
        );
    }
}

export default KeypointItem;