import React from 'react';

class VideoPlayer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            paused: true,
            muted: false,
            length: null,
            formattedLength: null,
            currentTime: null,
            formattedTime: null,
            volume: 0.5,
            videoId: this.props.id + `_video`,
            hideStub: false
        };
    }

    duration() {
        let dur = document.getElementById(this.state.videoId).duration;
        dur = dur.toFixed();

        this.setState({
            length: dur
        });

        return dur;
    }

    play() {
        this.duration();
        const v = document.getElementById(this.state.videoId);

        this.setState({
            paused: !this.state.paused
        });
        if (this.state.paused === true) {
            v.play();
            this.setState({
                paused: false
            });
        } else {
            v.pause();
            this.setState({
                paused: true
            });
        }
    }

    playStub() {
        this.setState({
            hideStub: true
        });
        this.play();
        if (window.screen.width < 585) {
            const v = document.getElementById(this.state.videoId);
            if (v.requestFullscreen) {
                v.requestFullscreen();
            } else if (v.webkitRequestFullscreen) { /* Safari */
                v.webkitRequestFullscreen();
            } else if (v.msRequestFullscreen) { /* IE11 */
                v.msRequestFullscreen();
            }
        }
    }

    onTimeUpdate(e) {
        let progress = document.getElementById(this.state.videoId + '__progressbar-completed');
        progress.style.width = Math.round((e.currentTarget.currentTime / e.currentTarget.duration) * 100) + '%';
    }

    openFullscreen() {
        const v = document.getElementById(this.state.videoId);
        if (v.requestFullscreen) {
            v.requestFullscreen();
        } else if (v.webkitRequestFullscreen) { /* Safari */
            v.webkitRequestFullscreen();
        } else if (v.msRequestFullscreen) { /* IE11 */
            v.msRequestFullscreen();
        }
    }

    mute() {
        const v = document.getElementById(this.state.videoId);
        if (v.muted) {
            v.muted = false;
            this.setState({
                muted: false
            });
        } else {
            v.muted = true;
            this.setState({
                muted: true
            });
        }
    }

    onVideoEnded() {
        this.setState({
            paused: true
        });
    }

    videoSearch(e) {
        const rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let percent = (x / rect.width).toFixed(2);
        const v = document.getElementById(this.state.videoId);
        v.currentTime = percent * v.duration;
    }

    render() {
        let product = this.props.product;
        return (
            <div className="video__item">
                <div className="video-item__play-stub" onClick={this.playStub.bind(this)} style={{display: this.state.hideStub ? 'none' : 'block'}}>
                    <div className="video-item__play-icon">
                        <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.517683 0.138764C0.677412 0.0463261 0.856175 0 1.03521 0C1.21375 0 1.39246 0.0463261 1.55289 0.138764L13.8913 9.10372C14.211 9.28843 14.409 9.63041 14.409 10.0002C14.409 10.3699 14.2115 10.7118 13.8913 10.8963L1.55289 19.8616C1.23268 20.0461 0.837945 20.0461 0.517951 19.8616C0.19769 19.6765 0 19.3344 0 18.9649V1.0351C0 0.665562 0.197368 0.323639 0.517683 0.138764Z" fill="currentColor" fillRule="evenodd"/>
                        </svg>
                    </div>
                </div>
                <video poster={product.img} src={product.video} id={this.state.videoId} onTimeUpdate={this.onTimeUpdate.bind(this)} onClick={this.play.bind(this)} onEnded={this.onVideoEnded.bind(this)}/>
                <div className="video-item__buttons" style={{display: this.state.hideStub ? 'flex' : 'none'}}>
                    <button onClick={this.play.bind(this)} className="video-item__play video-item__button">
                        {this.state.paused && <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.574843 0.166517C0.752208 0.0555913 0.95071 0 1.14951 0C1.34777 0 1.54621 0.0555913 1.72435 0.166517L15.4251 10.9245C15.7801 11.1461 16 11.5565 16 12.0002C16 12.4438 15.7807 12.8541 15.4251 13.0755L1.72435 23.834C1.36879 24.0554 0.930467 24.0554 0.575141 23.834C0.219518 23.6118 0 23.2013 0 22.7579V1.24212C0 0.798675 0.219161 0.388367 0.574843 0.166517Z" fill="white"/>
                        </svg>}
                        {!this.state.paused && <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.67857 0C1.19893 0 0 1.16881 0 2.61011V21.3899C0 22.8322 1.19893 24 2.67857 24C4.15821 24 5.35714 22.8317 5.35714 21.3899V2.61011C5.35714 1.16829 4.15875 0 2.67857 0Z" fill="white"/>
                            <path d="M12.3214 0C10.8418 0 9.64282 1.16881 9.64282 2.61011V21.3899C9.64282 22.8317 10.8418 24 12.3214 24C13.801 24 15 22.8317 15 21.3899V2.61011C15 1.16881 13.801 0 12.3214 0Z" fill="white"/>
                        </svg>
                        }
                    </button>
                    <div className="video-item__progressbar">
                        <div id={this.state.videoId + '__progressbar-completed'} className="video-item__progressbar-track"/>
                        <div className="video-item__progressbar-clickable" onClick={this.videoSearch.bind(this)}/>
                    </div>
                    <button className="video-item__muted video-item__button" onClick={this.mute.bind(this)}>
                        {this.state.muted && <svg xmlns="http://www.w3.org/2000/svg" width="306.257px" height="306.257px" viewBox="0 0 306.257 306.257">
                            <g fill="#ffffff" fillRule="evenodd">
                                <path d="M19.747,121.968v70.068c0,21.499,17.43,38.924,38.924,38.924h40.703l112.088,73.612 c11.351,6.271,26.808-5.883,34.372-21.256L47.261,84.75C31.333,89.628,19.747,104.438,19.747,121.968z"/>
                                <path d="M250.386,41.816c0-21.5-19.464-51.253-38.924-38.924L108.71,76.499l141.676,141.677V41.816z"/>
                                <path d="M55.463,83.202l193.146,193.145l18.88,18.874c3.459,3.469,8.005,5.204,12.547,5.204c4.541,0,9.087-1.735,12.552-5.204 c6.934-6.929,6.934-18.17,0-25.104l-42.197-42.197L103.037,80.566L38.771,16.314c-3.461-3.469-8.005-5.204-12.549-5.204 c-4.544,0-9.085,1.735-12.552,5.204c-6.937,6.928-6.937,18.17,0,25.101L55.463,83.202z"/>
                            </g>
                        </svg>}
                        {!this.state.muted && <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0078 21.4172C13.0078 21.9494 12.7432 22.4329 12.329 22.6586C12.1689 22.7466 11.9973 22.7891 11.8268 22.7891C11.5569 22.7891 11.2895 22.6813 11.0734 22.4732L4.61856 16.2581H1.1807C0.52873 16.2587 0 15.644 0 14.8865V9.42049C0 8.6626 0.52873 8.04823 1.1807 8.04823H4.61884L11.0737 1.83314C11.4264 1.49348 11.9154 1.42107 12.3293 1.64837C12.7432 1.87405 13.0081 2.35788 13.0081 2.88977L13.0078 21.4172ZM17.5453 19.2734C17.5166 19.2757 17.4889 19.277 17.4604 19.277C17.1485 19.277 16.8478 19.1338 16.6257 18.8753L16.4678 18.6912C16.0536 18.2109 16.005 17.4501 16.3537 16.9039C17.2379 15.5187 17.7046 13.8766 17.7046 12.154C17.7046 10.3011 17.1748 8.56324 16.1721 7.12798C15.7904 6.58246 15.8256 5.79015 16.2534 5.29333L16.411 5.10986C16.6469 4.8358 16.963 4.68643 17.305 4.71013C17.6381 4.72962 17.9492 4.91178 18.1604 5.21182C19.5513 7.18936 20.286 9.59032 20.286 12.1543C20.286 14.5423 19.6362 16.8153 18.4066 18.7266C18.2012 19.0451 17.8871 19.2452 17.5453 19.2734ZM22.4269 23.5132C22.2134 23.8065 21.9046 23.9825 21.574 23.9987C21.5578 23.9993 21.5413 24 21.5245 24C21.2118 24 20.9117 23.8565 20.6895 23.5983L20.5344 23.4181C20.101 22.9148 20.0716 22.1098 20.4657 21.5649C22.3699 18.9334 23.4189 15.5914 23.4189 12.154C23.4189 8.5785 22.2958 5.13649 20.2572 2.46211C19.8439 1.91919 19.8648 1.09732 20.3047 0.58427L20.4595 0.404052C20.6892 0.135835 20.9877 -0.0119117 21.33 0.000752317C21.655 0.011468 21.9624 0.178373 22.1784 0.460877C24.6427 3.68468 26 7.83782 26 12.154C26.0006 16.3068 24.7315 20.3411 22.4269 23.5132Z" fill="white"/>
                        </svg>
                        }
                    </button>
                    <button className="video-item__fullscreen video-item__button" onClick={this.openFullscreen.bind(this)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.18182 0H0.545472C0.243972 0 0 0.243972 0 0.545472V8.18182C0 8.48332 0.243972 8.7273 0.545472 8.7273H1.63636C1.93786 8.7273 2.18184 8.48332 2.18184 8.18182V2.18184H8.18182C8.48332 2.18184 8.7273 1.93786 8.7273 1.63636V0.545472C8.7273 0.243972 8.48332 0 8.18182 0Z" fill="white"/>
                            <path d="M23.4546 0H15.8182C15.5167 0 15.2727 0.243972 15.2727 0.545472V1.63636C15.2727 1.93786 15.5167 2.18184 15.8182 2.18184H21.8181V8.18182C21.8181 8.48332 22.0621 8.7273 22.3636 8.7273H23.4545C23.756 8.7273 24 8.48332 24 8.18182V0.545472C24 0.243972 23.7561 0 23.4546 0Z" fill="white"/>
                            <path d="M23.4546 15.2727H22.3637C22.0622 15.2727 21.8182 15.5167 21.8182 15.8182V21.8182H15.8182C15.5167 21.8182 15.2727 22.0621 15.2727 22.3636V23.4545C15.2727 23.756 15.5167 24 15.8182 24H23.4545C23.756 24 24 23.756 24 23.4545V15.8181C24 15.5166 23.7561 15.2727 23.4546 15.2727Z" fill="white"/>
                            <path d="M8.18182 21.8182H2.18184V15.8182C2.18184 15.5167 1.93786 15.2727 1.63636 15.2727H0.545472C0.243972 15.2728 0 15.5167 0 15.8182V23.4545C0 23.756 0.243972 24 0.545472 24H8.18182C8.48332 24 8.7273 23.756 8.7273 23.4545V22.3636C8.7273 22.0622 8.48332 21.8182 8.18182 21.8182Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>
        )
    }
}

export default VideoPlayer;