import React from 'react';
import {NavLink} from "react-router-dom";
import BannerAnimation from "./BannerAnimation";
import RightArrow from '../svg/arrow_right_button.svg';
import { animate } from '../pages/Home'

class PageTopBanner extends React.Component {

    componentDidMount() {
        if (this.props.home) {
            document.getElementsByClassName('page-banner__link')[0].addEventListener('click', (e) => {
                e.preventDefault();
                let target = document.getElementsByClassName("home__about")[0];
                animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 50, 300, true);
            })
        } else {
            document.getElementsByClassName('page-banner__link')[0].addEventListener('click', (e) => {
                e.preventDefault();
                let target = document.getElementsByClassName("bottom-banner__box")[0];
                animate(document.scrollingElement || document.documentElement, "scrollTop", "", document.documentElement.scrollTop, target.offsetTop - 50, 800, true);
            })
        }
    }

    render() {
        let title = '', text = '', link = '', linkText = '';
        if (this.props.home) {
            title = 'Как получить формулу идеального digital?';
            text = 'Профессора спорят об этом уже не первый год, а мы в это время пишем код, управляем проектами, цифрами доказываем результаты своей работы. Уверены, идеальный digital - значит работающий.';
            link = '#';
            linkText = 'Подробнее'
        }
        if (this.props.advertising) {
            title = 'Реклама на поиске и медийных сетях';
            text = 'Настраиваем рекламу для новых заявок, клиентов и покупателей из поисковых систем. Объединяем все основные инструменты для получения продающего трафика.';
            link = '/brief';
            linkText = 'Начать работу'
        }
        if (this.props.analytics) {
            title = 'Предпроектная аналитика';
            text = 'Это один из видов исследований, помогающий определить проблемы и поставить задачи будущего проекта. Основа для принятия верных решений.';
            link = '/brief';
            linkText = 'Начать работу'
        }
        if (this.props.web) {
            title = 'Веб-разработка';
            text = 'Специализируемся на разработке и развитии сайтов с использованием 1C Битрикс, React. Погружаясь в проект, видим каждую страницу как интерфейс, требующий не только оформления, но и проработки контента.';
            link = '/brief';
            linkText = 'Начать работу'
        }
        if (this.props.seo) {
            title = 'Поисковое продвижение';
            text = 'Поможем найти и исправить ошибки, мешающие сайту попасть на первую страницу выдачи поисковых систем, сделаем его удобнее для пользователя.';
            link = '/brief';
            linkText = 'Начать работу'
        }
        return (
            <div className="page-banner">
                <div className="wrapper">
                    <div className="page-banner__content">
                        <div className="page-banner__title h1">{title}</div>
                        <div className="page-banner__description text">{text}</div>
                        <NavLink to={link} className="page-banner__link">
                            <span>{linkText}</span>
                            <img src={RightArrow} alt=""/>
                        </NavLink>
                    </div>
                </div>
                <div className="page-banner__animation">
                    {this.props.home && <BannerAnimation home/>}
                    {this.props.advertising && <BannerAnimation advertising/>}
                    {this.props.analytics && <BannerAnimation analytics/>}
                    {this.props.web && <BannerAnimation web/>}
                    {this.props.seo && <BannerAnimation seo/>}
                </div>
            </div>
        )
    }
}

export default PageTopBanner;